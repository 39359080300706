<template>
  <b-modal
    id="modal-create-pay-allowance-type"
    ref="modal-create-pay-allowance-type"
    centered="centered"
    :title="
      (payAllowanceType.id ? 'Modifier' : 'Ajouter') + ` un type d'indemnité`
    "
    @ok="create"
  >
    <div v-if="isCreatingPayAllowanceType">
      <div class="text-center flex-center">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          Chargement...
        </div>
      </div>
    </div>
    <validation-observer v-else ref="formCreatePayAllowanceType">
      <b-row>
        <b-col cols="12" class="d-flex">
          <b-form-group label="Libellé *" label-for="Libellé" style="flex: 1">
            <validation-provider
              #default="{ errors }"
              name="Libellé"
              rules="required"
            >
              <b-form-input
                id="label"
                v-model="payAllowanceType.label"
                :state="errors.length > 0 ? false : null"
                class="not-autocomplete"
                autocomplete="nope"
                aria-autocomplete="nope"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="d-flex">
          <b-form-group
            label="Libellé court *"
            label-for="shortLabel"
            style="flex: 1"
          >
            <validation-provider
              #default="{ errors }"
              name="shortLabel"
              rules="required|max:10"
            >
              <b-form-input
                id="shortLabel"
                v-model="payAllowanceType.shortLabel"
                :state="errors.length > 0 ? false : null"
                class="not-autocomplete"
                autocomplete="nope"
                aria-autocomplete="nope"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Variable" label-for="variable">
            <b-form-input
              id="variable"
              v-model="payAllowanceType.paymentVariable"
              class="not-autocomplete"
              autocomplete="nope"
              aria-autocomplete="nope"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Type" label-for="quantificationType">
            <v-select
              id="quantificationType"
              :reduce="(type) => type.id"
              v-model="payAllowanceType.quantificationType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="quantificationTypeOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Formule" label-for="formula">
            <b-form-input
              id="formula"
              v-model="payAllowanceType.formula"
              class="not-autocomplete"
              autocomplete="nope"
              aria-autocomplete="nope"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label-for="isValued"
            ><span>Ce type d'indemnité est-il valorisé ?</span>
            <b-form-checkbox
              class="float-right mr-0"
              id="isValued"
              checked="false"
              v-model="payAllowanceType.isValued"
              switch="switch"
              inline="inline"
            ></b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row> </validation-observer
    ><template #modal-footer="{ ok, cancel }">
      <div class="w-100">
        <b-button
          class="mr-1 float-left"
          v-if="payAllowanceType.id && archive"
          variant="outline-danger"
          @click="
            deletePayAllowanceTypeLocal(
              payAllowanceType.id,
              payAllowanceType.label
            )
          "
          ><feather-icon icon="TrashIcon"
        /></b-button>
        <b-button
          class="float-left"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-if="payAllowanceType.id && !archive"
          variant="outline-danger"
          @click="
            archivePayAllowanceTypeLocal(
              payAllowanceType.id,
              payAllowanceType.label
            )
          "
          ><feather-icon icon="ArchiveIcon"
        /></b-button>
        <b-button
          class="float-left"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-if="payAllowanceType.id && archive"
          variant="outline-warning"
          @click="
            restorePayAllowanceTypeLocal(
              payAllowanceType.id,
              payAllowanceType.label
            )
          "
          ><feather-icon icon="ArrowDownIcon"
        /></b-button>
        <b-button class="float-right" variant="primary" @click="ok()">{{
          payAllowanceType.id ? "Modifier" : "Ajouter"
        }}</b-button>
        <b-button
          class="float-right mr-1"
          variant="outline-primary"
          @click="cancel()"
          >Annuler</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  defineRule,
  configure,
  localize,
} from "vee-validate";
import { required, url, email, max } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
      max: "Ce champ ne doit pas dépasser 3 caractères",
    },
  }),
});
localize("fr");

export default {
  data() {
    return {
      required,
      url,
      max,
      email,
      payAllowanceType: {
        label: "",
        shortLabel: "",
        paymentVariable: "",
        formula: "",
        isValued: false,
        quantificationType: 0,
      },
      quantificationTypeOptions: [
        { label: "Checkbox", id: 0 },
        { label: "Quantité", id: 1 },
        { label: "Montant", id: 2 },
      ],
    };
  },
  props: {
    archive: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    popupCreatePayAllowanceTypeEvent(id) {
      if (id > 0) {
        this.getPayAllowanceTypeById({
          payAllowanceTypeId: id,
        }).then((res) => {
          this.payAllowanceType = {
            ...res,
            formula: res.formula ? res.formula : "",
          };
        });
      } else {
        this.initializeForm();
      }
    },
    initializeForm() {
      this.payAllowanceType = {
        label: "",
        shortLabel: "",
        paymentVariable: "",
        formula: "",
        isValued: false,
        quantificationType: 0,
      };
    },
    create(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.formCreatePayAllowanceType.validate().then((success1) => {
        if (success1) {
          if (this.payAllowanceType.id) {
            this.updatePayAllowanceType({
              payAllowanceType: this.payAllowanceType,
            }).then((res) => {
              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-pay-allowance-type");
                this.$emit("refreshGrid");
              });
            });
          } else {
            this.createPayAllowanceType({
              payAllowanceType: this.payAllowanceType,
            }).then((res) => {
              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-pay-allowance-type");
                this.$emit("refreshGrid");
              });
            });
          }
        }
      });
    },
    archivePayAllowanceTypeLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer ce type d'indemnité en consultant la liste de vos archives.",
          {
            title:
              "Êtes-vous sûr de vouloir archiver le type d'indemnité \"" +
              label +
              '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archivePayAllowanceTypes({ payAllowanceTypeIds: [id] }).then(
              (res) => {
                this.$nextTick(() => {
                  this.$bvModal.hide("modal-create-pay-allowance-type");
                  this.$emit("refreshGrid");
                });
              }
            );
          }
        });
    },
    restorePayAllowanceTypeLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez ré-utiliser ce type d'indemnité dans tous les modules de votre application",
          {
            title:
              "Êtes-vous sûr de vouloir restaurer le type d'indemnité \"" +
              label +
              '" ?',
            size: "sm",
            okVariant: "warning",
            okTitle: "Restaurer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.restorePayAllowanceType(id).then((res) => {
              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-pay-allowance-type");
                this.$emit("refreshGrid");
              });
            });
          }
        });
    },
    deletePayAllowanceTypeLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title:
            "Êtes-vous sûr de vouloir supprimer le type d'indemnité \"" +
            label +
            '" ?',
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deletePayAllowanceType(id).then((res) => {
              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-pay-allowance-type");
                this.$emit("refreshGrid");
              });
            });
          }
        });
    },
    ...mapActions([
      "createPayAllowanceType",
      "updatePayAllowanceType",
      "getPayAllowanceTypeById",
      "archivePayAllowanceTypes",
      "restorePayAllowanceType",
      "deletePayAllowanceType",
    ]),
  },
  computed: {
    ...mapGetters(["isCreatingPayAllowanceType"]),
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
};
</script>

<style></style>
