<template lang="pug">
div.d-flex.flex-column
    HeaderGrid(:name="name" :uid="uid" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" :archiveMode="archiveMode" :archive="archive" @refreshRow="refreshRow" :indexSearch="indexSearch")
    .d-flex.w-100.h-100(style="overflow: hidden;")
      .text-center.flex-center.w-100(v-if='isLoadingPayAllowanceTypesList || !headerLoaded')
        .loading-bg-inner
            .loader
              .outer
              .middle
              .inner
        .mt-5
            br
            br
            br
            | Chargement de la liste des variables de paie...
      sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :editSettings='editSettings'  :allowGrouping="allowGrouping" :gridData="dataFiltered" :name="name" :headerData="headerData" @rowClicked="onRowClicked"  @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton" @actionBegin="actionBegin" @deleteButtonClicked="deleteButtonClicked" @archiveButtonClicked="archiveButtonClicked")
    popup-create-pay-allowance-type(:archive="archive" ref="popupCreatePayAllowanceType" @refreshGrid="refreshGrid")
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";
import PopupCreatePayAllowanceType from "@/components/report/report-settings/popup-report-settings/PopupCreatePayAllowanceType";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

export default {
  components: {
    SyncGrid,
    HeaderGrid,
    PopupCreatePayAllowanceType,
  },
  data() {
    return {
      indexSearch: 0,
      name: "payAllowanceType",
      uid: "payAllowanceTypes",
      archive: false,
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      selectedRows: [],

      dateRanger: false,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: false,
      archiveMode: false,
      searchPlaceholder: "Rechercher un type d'indemnité de paie",
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Dialog",
        titleAdd: "Nouveau type d'indemnité",
        titleEdit: "Modifier le type d'indemnité",
        titleField: "label",
      },
      allowGrouping: false,
    };
  },
  beforeMount() {
    this.headerData = [
      {
        type: "string",
        field: "paymentVariable",
        headerText: "Variable",
        width: 200,
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        allowEditing: true,
        showInColumnChooser: true,
        visible: true,
        validationRules: { required: true, minLength: 2 },
      },
      {
        type: "string",
        field: "label",
        headerText: "Libellé",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        allowEditing: true,
        showInColumnChooser: true,
        visible: true,
        validationRules: { required: true, minLength: 2 },
        clipMode: "EllipsisWithTooltip",
        template: "stripHtmlTemplate",
      },
      {
        type: "string",
        field: "shortLabel",
        headerText: "Libellé court",
        width: "auto",
        minWidth: 50,
        width: 200,
        allowFiltering: true,
        allowSorting: true,
        allowEditing: true,
        showInColumnChooser: true,
        visible: true,
        validationRules: { required: true, minLength: 2 },
        clipMode: "EllipsisWithTooltip",
        template: "stripHtmlTemplate",
      },
      {
        type: "boolean",
        field: "isValued",
        headerText: "Lié à l'affaire",
        width: 120,
        minWidth: 120,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        displayAsCheckBox: true,
        template: "booleanTemplate",
      },
      {
        type: "string",
        field: "quantificationType",
        filterTemplate: "quantificationFilterTemplate",
        headerText: "Type",
        width: 160,
        maxWidth: 160,
        allowFiltering: true,
        allowSorting: true,
        allowEditing: true,
        showInColumnChooser: true,
        visible: true,
        // editType: "dropdownedit",
        valueAccessor: "dropdown",
        edit: {
          source: [
            { label: "Checkbox", id: 0 },
            { label: "Quantité", id: 1 },
            { label: "Montant", id: 2 },
          ],
          fields: { text: "label", value: "id" },
          type: "dropdown", //<<FIX
        },
      },
    ];
  },
  computed: {
    ...mapGetters([
      "payAllowanceTypesList",
      "isLoadingPayAllowanceTypesList",
      "workspaceSelected",
    ]),
    dataFiltered() {
      return this.$store.getters[this.name + "sList"];
    },
  },
  created() {
    this.getPayAllowanceTypes({}).then(async (res) => {
      if (res.length) {
        await this.setColumnsByObject(res[0], null, this);
        this.indexSearch++;
      }
      this.headerLoaded = true;
    });
  },
  methods: {
    ...mapActions([
      "getPayAllowanceTypes",
      "createPayAllowanceType",
      "updatePayAllowanceType",
      "archivePayAllowanceTypes",
      "deletePayAllowanceType",
    ]),
    formatCurrency,
    setColumnsByObject,
    async actionBegin(args) {
      if (args.requestType === "save" && args.action === "add") {
        await this.createPayAllowanceType({
          payAllowanceType: args.data,
        });
        this.$refs.overviewgrid.$refs.overviewgrid.refresh();
      }
      if (args.requestType === "save" && args.action === "edit") {
        await this.updatePayAllowanceType({
          payAllowanceType: args.data,
        });
        this.$refs.overviewgrid.$refs.overviewgrid.refresh();
      }
    },
    refreshGrid(res) {
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    popupCreatePayAllowanceType(id) {
      this.$bvModal.show("modal-create-pay-allowance-type");
      this.$refs[
        "popupCreatePayAllowanceType"
      ].popupCreatePayAllowanceTypeEvent(id);
    },
    clickAction() {
      this.popupCreatePayAllowanceType("");
    },
    onRowClicked(event) {
      this.popupCreatePayAllowanceType(event.rowData.id);
    },
    async archiveButtonClicked(args) {
      await this.archivePayAllowanceTypes({
        payAllowanceTypeIds: [args.rowData.id],
      });
      this.$refs.overviewgrid.$refs.overviewgrid.closeEdit();
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    async deleteButtonClicked(args) {
      await this.deletePayAllowanceType(args.rowData.id);
      this.$refs.overviewgrid.$refs.overviewgrid.closeEdit();
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else if (args.item.type == "archive") {
        this.archive = true;
      } else if (args.item.type == "current") {
        this.archive = false;
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
  },
};
</script>
